<template>
    
  <div class="row justify-center">
    <h4>Le fonctionnement</h4>
  </div>

  <div class="row justify-center">
    <div class="col-xs-10 col-md-6">

      <h5 class="indent">
        Les rôles au sein de Mandragore :
      </h5>

      <div class="regular-text indent">
        Pour mieux s’organiser et faire vivre l’association, plusieurs rôles (que l’on peut d’ailleurs retrouver en jeu), ont été définis. Ces rôles n’ont pas tous de statut officiel au regard de l’association, mais sont un outil pratique pour notre fonctionnement.
        <br><br>
        Commençons d’abord par les rôles officiels de l’association :
      </div>

        <ul class="regular-text">
          <li class="list-element">Le <span style="font-weight: bold;">président</span>, le <span style="font-weight: bold;">secrétaire</span> et le <span style="font-weight: bold;">trésorier</span>.
          <br>
          C’est l’organe de pilotage, il prend toutes les décisions courantes de gestion de l’association et veille à leurs exécutions.</li>
          <li class="list-element"><span style="text-decoration: underline;"><i>Les conseillers</i></span> : Ils ont un rôle de supervision. En effet, ils sont garant du respect de l’esprit Mandragore par les équipes des Mangroves et en cas de désaccord, ils effectuent un arbitrage. C’est aussi eux qui nomment le bureau.</li>
        </ul>

      <div class="regular-text" style="margin-top: 40px;">
        Et puis, viennent les rôles qui ont été créés pour le bon fonctionnement de Mandragore. Il ne font pas officiellement partie des statuts mais sont indispensables au fonctionnement de l’association.
      </div>

      <ul class="regular-text">
        <li class="list-element"><span style="text-decoration: underline;"><i>Baobabs :</i></span> C’est un “chef” de Mangrove. Il va guider son équipe de palétuviers afin de faire vivre la mangrove.</li>
        <li class="list-element"><span style="text-decoration: underline;"><i>Palétuviers :</i></span> Il fait, avec les autres Palétuviers et le Baobab, la tâche pour laquelle la Mangrove a été créée (il peut également être Baobab d’une autre Mangrove, Plante, Conseiller ou même Président).</li>
        <li class="list-element"><span style="text-decoration: underline;"><i>Séquoïa :</i></span> C’est un ancien Baobab (ou officier quand les Mangroves n’existaient pas) qui n’est impliqué dans aucune Mangrove à l’heure actuelle mais qui souhaite garder une voix pour ce qui concerne la gestion globale de Mandragore.</li>
        <li class="list-element"><span style="text-decoration: underline;"><i>Plante :</i></span> C’est une personne qui a été parrainé avec succès.</li>
        <li class="list-element"><span style="text-decoration: underline;"><i>Feuille :</i></span> C’est une personne qui a eu un parrainage simplifié. Elle est membre de Mandragore mais avec moins d’accès sur le discord.</li>
        <li class="list-element"><span style="text-decoration: underline;"><i>Bourgeon :</i></span> C’est une personne guildée en jeu ou sur le serveur discord qui n’a pas été parrainée.</li>
      </ul>

      <div class="regular-text">
        Chaque membre peut prendre plusieurs rôles, en fonction de son implication dans les activités de la communauté qu’elles soient en jeu mais aussi IRL !
      </div>


      <h5 class="indent">
        Les mangroves :
      </h5>

      <div class="regular-text indent">
        Face à la taille de certains défis, il faut parfois s’y mettre à plusieurs. On constitue alors des petits groupes (voire des raids) de travail : les Mangroves.<br>
      </div>

      <div style="display:flex; justify-content: center">
        <img src="/img/fonctionnement/mangrove.png" style="margin-top: 20px; margin-bottom:20px; height: 400px; max-width: 800px;" />
      </div>

      <div class="regular-text indent">
        La tâche d’une Mangrove, sous la gestion bienveillante de son Baobab, est d’organiser des activités à destination des membres : Le raid sur Wow, les IRLs, la gestion des parrainages… Faire partie d’une Mangrove, c’est s’impliquer dans un projet et le porter ensemble jusqu’à ce qu’il se concrétise (ou que vous ayez envie de passer à autre chose, on n'est pas des monstres).
      </div>

      <h5 class="indent">
        La guilde et l’association :
      </h5>

      <div class="regular-text indent">
        A l'origine une simple guilde WoW, Mandragore a eu besoin, au fil du temps et des IRLs, d’acquérir un statut légal pour organiser des évènements de plus grande ampleur et avoir des prix chez V&B. C’est comme ça qu'en 2018 est née l’association Mandragore (loi 1901, tmtc). Elle offre un statut juridique et permet de faciliter l’organisation des IRLs et des autres évènements de la communauté hors jeu, mais aussi d’officialiser le regroupement d’amis qu’est devenu Mandragore.<br><br>
        L’association Mandragore et la guilde Mandragore sont deux choses distinctes. Il n’est en aucun cas obligatoire de faire partie de l’association Mandragore pour être un membre à part entière de Mandragore et participer aux activités que cette communauté propose.<br><br>
        Une fois membre de la guilde, l’adhésion à l’association peut être réalisée via le site et donne le droit de vote aux assemblées générales annuelles, tenues pendant les IRL.
      </div>

    </div>
  </div>

  <div class="row justify-center" style="margin-top: 40px; margin-bottom: 30px;">
    <div class="col-xs-10 col-md-6" style="display: flex; justify-content: space-evenly;">

    <router-link to="/association/qui-sommes-nous">
      <q-btn color="primary" label="◄&nbsp;&nbsp;&nbsp;Qui sommes nous ?" />
    </router-link>

    <router-link to="/association/charte">
      <q-btn color="primary" label="La charte&nbsp;&nbsp;&nbsp;►" />
    </router-link>

  </div>
</div>  

</template>  


<style scoped>

.list-element {
  margin-bottom: 10px;
}

.indent {
  text-indent: 50px;
}

</style>